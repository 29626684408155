(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("prop-types"), require("react"), require("classnames"), require("react-dom"), require("react-transition-group"));
	else if(typeof define === 'function' && define.amd)
		define(["prop-types", "react", "classnames", "react-dom", "react-transition-group"], factory);
	else if(typeof exports === 'object')
		exports["FontIconPicker"] = factory(require("prop-types"), require("react"), require("classnames"), require("react-dom"), require("react-transition-group"));
	else
		root["FontIconPicker"] = factory(root["PropTypes"], root["React"], root["classNames"], root["ReactDOM"], root["ReactTransitionGroup"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__13__) {
return 